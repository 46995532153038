import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import CertificationOption from "../components/CertificationOption"

export default function SpainCertification() {
  const path = [
    { label: "Inicio", to: "/" },
    { label: "Certificaciones", to: "/certificaciones" },
    { label: "España (Guía de certificación EFP, EFA, EIP y EIA)" },
  ]
  return (
    <Layout>
      <Hero image="/imgs/certificaciones-hero.jpg" text="Certificaciones" />
      <div className="container950">
        <Breadcrumb path={path} />
        <TitleBlock
          title="España (Guía de certificación EFP, EFA, EIP y EIA)"
          icon="fas fa-file-alt"
        />
        <span className="SpainCertification-text">
          EFPA España adapta las guías de certificación o programas de
          contenidos de EFPA Europa, siendo los programas para la obtención de
          las certificaciones en nuestro país las siguientes:
        </span>
        <div className="row efpa-row mb-5">
          <div className="col-12 col-md-6 Certification-padding-r">
            <CertificationOption
              icon="fas fa-project-diagram"
              title="EFP"
              text="European Financial Planner"
              link="/"
              usePdf
            />
            <CertificationOption
              icon="fas fa-user-tie"
              title="EFA"
              text="European Financial Advisor"
              link="/"
              usePdf
            />
          </div>
          <div className="col-12 col-md-6 Certification-padding-l">
            <CertificationOption
              icon="far fa-file-alt"
              title="EIP"
              text="European Investment Practitioner"
              link="/"
              usePdf
            />
            <CertificationOption
              icon="far fa-id-card"
              title="EIA"
              text="European Investment Assistant"
              link="/"
              usePdf
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
